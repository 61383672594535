import React, { useState } from 'react';
import Form from 'components/common/Form';
import FormDataProvider from 'components/common/Form/FormDataProvider/FormDataProvider';
import FormGroup from '../../common/Form/FormGroup';
import ValidatedInput from '../../common/Form/ValidatedInput';
import { INPUT_MODES, INPUT_TYPES } from '../../common/Form/types/enums';
import { Button } from '../../common/Button/Button';
import styles from './login.module.scss';
import SyncedInput from '../../common/Form/SyncedInput';
import { defaultFormState } from '../../common/Form/types/types';
import { Redirect } from 'react-router-dom';
import { KeyboardEvent } from 'react';
import { ForgotPasswordModal } from './ForgotPasswordModal';
import { sendCredentialsToServers } from 'components/common/utils/auth';
import Icon from '../../common/Icons';
import { useStore } from 'components/store';

enum LoginPhase {
  userInput,
  pending,
  noResponse,
  success
}

const initialState = {
  user: '',
  password: '',
  rememberMe: false
};

const scrollToTop = (): void => {
  window.scrollTo(0, 0);
};
const defaults = defaultFormState(initialState);

export default function Login(): JSX.Element {
  const [loginPhase, setLoginPhase] = useState(LoginPhase.userInput);
  const [serverError, setServerError] = useState('');
  const [redirect, setRedirect] = useState('');
  const [formData, setFormData] = useState(defaults);
  const [login, logout] = useStore(state => [state.login, state.logout]);

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const openForgotPassword = (): void => {
    setShowForgotPasswordModal(true);
  };

  const submitCredentials = async (): Promise<void> => {
    setLoginPhase(LoginPhase.pending);
    const credentials = new FormData(document.forms.namedItem('login') as HTMLFormElement);
    const [mhResponse, apiResponse] = await sendCredentialsToServers(credentials);
    const localLoggedIn = mhResponse.ok && (mhResponse.data.isLoggedIn ?? false);

    // await the api response
    const apiLoggedIn = apiResponse.ok && (apiResponse.data.isLoggedIn ?? false);

    if (mhResponse.errors?.length > 0) {
      // the server sent a message, display it
      setServerError(mhResponse.errors[0].messages[0]);
    } else {
      setServerError('');
      if (!mhResponse.ok || !apiResponse.ok) {
        // failed to get a response from one of the servers
        setLoginPhase(LoginPhase.noResponse);
      } else if (!localLoggedIn || !apiLoggedIn) {
        if (mhResponse.data.redirect.length) {
          setRedirect(mhResponse.data.redirect);
        } else {
          setFormData(state => ({
            ...state,
            SERVER_ERRORS: { password: ['The email or password was entered incorrectly.'] }
          }));
        }
      } else {
        if (apiLoggedIn && localLoggedIn) {
          login(apiResponse.data.userInfo);
        } else {
          logout();
        }
        window.setTimeout(scrollToTop, 250);
        setLoginPhase(LoginPhase.success);
      }
    }
  };

  const errors = [...formData.ERROR_STATE().CLIENT_ERRORS, ...formData.ERROR_STATE().SERVER_ERRORS];

  const handleEnterKey = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      submitCredentials();
    }
  };

  return redirect.length > 0 ? (
    <Redirect to={redirect} />
  ) : (
    <div className={styles.login}>
      <div className={styles.largeHero} />
      <div className={styles.formContainer}>
        <div className={styles['logo-section']}>
          <Icon name="betaMHLogoDark" className={styles['dark-logo']} />
          <Icon name="betaMHLogoWhite" className={styles['white-logo']} />
        </div>
        <FormDataProvider state={formData} setState={setFormData}>
          <Form name={'login'} onSubmit={submitCredentials}>
            <FormGroup>
              <SyncedInput
                source={'email'}
                render={(value, onBlur, onChange): JSX.Element => (
                  <ValidatedInput
                    id="login-email"
                    testId="login-email"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={handleEnterKey}
                    type={INPUT_TYPES.EMAIL}
                    inputMode={INPUT_MODES.EMAIL}
                    name="email"
                    source="email"
                    placeholder="Email"
                    inputSize="large"
                    required
                    className={styles.loginInput}
                  />
                )}
              />
              <SyncedInput
                source={'password'}
                render={(value, onBlur, onChange): JSX.Element => (
                  <ValidatedInput
                    id="login-password"
                    testId="login-password"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={handleEnterKey}
                    type={INPUT_TYPES.PASSWORD}
                    inputMode={INPUT_MODES.TEXT}
                    name="password"
                    source="password"
                    placeholder="Password"
                    className={styles.loginInput}
                    inputSize="large"
                    required
                  />
                )}
              />
              <input type="hidden" id="login-rememberme" name="RememberMe" value="true" />
              <div role="button" className={styles.toggle}>
                <Button kind="text" className={styles.resetbtn} onClick={openForgotPassword}>
                  Forgot your password?
                </Button>
              </div>
              <Button type="submit" className={styles.loginButton} isDisabled={errors.length > 0}>
                Log In
              </Button>
              <div className={styles.error}>
                {serverError.length > 0
                  ? serverError
                  : loginPhase === LoginPhase.noResponse
                    ? 'There was an error trying to log in. Please try again.'
                    : ''}
              </div>
            </FormGroup>
          </Form>
        </FormDataProvider>
      </div>

      <ForgotPasswordModal openState={showForgotPasswordModal} setOpenState={setShowForgotPasswordModal} />
    </div>
  );
}
