import { create } from 'zustand';
import { createAuthSlice } from './AuthStore';
import { createGlobalStoreSlice } from './GlobalStore';
import { createProfileSlice } from './ProfileStore.ts';
import { createListingManagerSlice } from './ListingManagerStore';
import { AuthSlice, GlobalStoreSlice, ProfileSlice, ListingManagerSlice } from './StoreSlices';

export const useStore = create<AuthSlice & ProfileSlice & GlobalStoreSlice & ListingManagerSlice>()((...args) => ({
  ...createAuthSlice(...args),
  ...createProfileSlice(...args),
  ...createGlobalStoreSlice(...args),
  ...createListingManagerSlice(...args)
}));
